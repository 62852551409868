<template>
    <span v-if="value === 'SIMPLE'" class="me-1">
        <Popper class="popperDark" arrow hover content="SIMPLE">
            <fa :icon="['far', 'circle']" size="lg" />
        </Popper>
    </span>
    <span v-if="value === 'KYC'" class="me-1">
        <Popper class="popperDark" arrow hover content="KYC">
            <fa icon="circle-half-stroke" size="lg"/>
        </Popper>
    </span>
    <span v-if="value === 'STRICT'" class="me-1">
        <Popper class="popperDark" arrow hover content="STRICT">
            <fa icon="circle"  size="lg"/>
        </Popper>
    </span>
</template>

<script>
import { ref, onMounted } from 'vue'
import Popper from 'vue3-popper'
export default {
    components: { Popper },
    props: [ 'value' ],
    setup (props) {

        onMounted(() => {
        })

        return {}
    }
}
</script>

<style>

</style>